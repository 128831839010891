const map = L.map("map").setView([21.17128044628222, 106.06725264262127], 50);

L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
  attribution: "© OpenStreetMap contributors",
}).addTo(map);

const specificLocation = [21.17128044628222, 106.06725264262127];
L.marker(specificLocation)
  .addTo(map)
  .bindPopup("Parkview City - Dabaco Huyền Quang")
  .openPopup();
const img1 = new URL(
  "../assets/Vô-tình-gây-tổn-thương-cho-con-nhiều-năm-mà-cha-mẹ-không-biết.jpg",
  import.meta.url
);
const img2 = new URL(
  "../assets/Người-cha-nóng-tính-và-hành-trình-chữa-lành-cảm-xúc.png",
  import.meta.url
);
const img3 = new URL(
  "../assets/Cơn-giận-của-mẹ-là-tổn-thương-của-con.png",
  import.meta.url
);
const img4 = new URL(
  "../assets/Tuổi-dậy-thì-và-sự-bất-lực-từ-người-mẹ.png",
  import.meta.url
);
const img5 = new URL(
  "../assets/Sống-mất-phương-hướng-Lãnh-cảm-với-chồng-Mất-kết-nối-với-con.png",
  import.meta.url
);
const img6 = new URL(
  "../assets/Mẹ-áp-đặt-làm-tổn-thương-con.png",
  import.meta.url
);
const img7 = new URL(
  "../assets/Mẹ-Chính-Là-Niềm-Tự-Hào-Của-Con!.png",
  import.meta.url
);
const thumbK1 = new URL("../assets/Thumb-K1.jpg", import.meta.url);
const thumbK2 = new URL("../assets/Thumb-K2.jpg", import.meta.url);
const thumbK3 = new URL("../assets/Thumb-K3.jpg", import.meta.url);
const slideContentExperience = [
  {
    title: "Tuổi dậy thì và sự bất lực từ người mẹ",
    description: `
          Trong một ngôi nhà nhỏ, tình yêu từng được nuôi dưỡng bằng những bữa cơm ấm áp và nụ cười rạng rỡ. Người mẹ, với trái tim rộng lớn và đôi bàn tay đã từng nghĩ rằng mình có thể che chở và bảo vệ tất cả những đứa con mình yêu thương, dù cho không phải cùng chung dòng máu.<br />
  Nhưng thời gian trôi đi, sáu đứa con nuôi ngày nào giờ đã trưởng thành, và theo cách của riêng mình, họ đang dần rời xa. Căn nhà ngày trước từng tràn ngập tiếng cười, giờ đây trở nên yên lặng đến nghẹn ngào.<br />
  Dù khoảng cách có lớn dần, mẹ vẫn tin rằng tình yêu của một người mẹ có thể hàn gắn mọi vết thương. Đôi khi, người mẹ phải biết chờ đợi, hy vọng, và tiếp tục yêu, ngay cả khi những đứa con đang bận rộn đi tìm con đường của riêng mình trong cuộc sống rộng lớn và đầy những khó khăn!
        `,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/LƯƠNG HUỲNH KHẢ ÁI.mp4",
    thumb: img4,
  },
  {
    title: "Vô tình gây tổn thương cho con nhiều năm mà cha mẹ không biết",
    description: `
          "Ôi! Mình đã vô tình khiến con mình tổn thương..."<br />
  Đây là lời thú nhận đầy xót xa của cô Đỗ Thị Thu Chiến - đến từ Hồ Chí Minh, khi nhớ lại khoảng thời gian tưởng chừng như không thể nào gỡ rối được mối quan hệ với con trai lớn của mình.<br />
  Hai mẹ con ngày càng khó trò chuyện, không khí lúc nào cũng căng thẳng. Cảm giác bực bội, thất vọng cứ chất chứa trong lòng, làm cho khoảng cách giữa họ càng thêm xa cách.<br />
  Cô Chiến từng không hiểu tại sao mọi thứ lại trở nên như vậy, cho đến khi cô tìm ra lời giải đáp từ những kiến thức mà cô Lanh chia sẻ. Những gì cô khám phá được đã khiến cô giật mình nhận ra nguyên nhân sâu xa của vấn đề là điều mà cô chưa từng nghĩ đến.
        `,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/ĐỖ THỊ THU CHIẾN.mp4",
    thumb: img1,
  },
  {
    title: "Người cha nóng tính và hành trình chữa lành cảm xúc",
    description: `
          Anh Phan Hoàng Nhẫn - người từng chìm đắm trong những suy nghĩ tiêu cực, luôn phán xét mọi vấn đề trong công việc và cuộc sống, dễ nóng nảy, hay cáu gắt vô cớ với vợ con. Dù khao khát nuôi dạy con đúng cách, nhưng anh vẫn không thoát khỏi vòng xoáy của những cảm xúc tiêu cực, liên tục làm tổn thương những người thân mà chính anh hết mực yêu thương.<br />
  Trong anh luôn đau đáu một câu hỏi: "Tại sao mình yêu thương gia đình đến thế, nhưng lại luôn khiến họ đau lòng, xa cách?"
  Hành trình thay đổi của anh Nhẫn bắt đầu từ chính khoảnh khắc anh đối diện với câu hỏi ấy. Đến với cô Lanh và học viện Minh Trí Thành, anh đã tìm thấy con đường giúp bản thân thức tỉnh. Hành trình Kiến tạo offline tháng 10 vừa qua tại Hà Nội đã mở ra cho anh và gia đình một bước ngoặt mới. Những thay đổi đã dần xuất hiện, từng hành động nhỏ được thực hiện hàng ngày với tình yêu thương và nhận thức sâu sắc hơn.
        `,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/HOÀNG NHẪN.mp4",
    thumb: img2,
  },
  {
    title: "Cơn giận của mẹ làm tổn thương con",
    description: `
          “Lần đầu tiên mình biết nói những lời yêu thương, xin lỗi với con.”
  Đó là lời tâm sự đầy xúc động của chị Liên khi đến vớ hành trình "Kiến Tạo Cuộc Đời Mới - Đường Về Hạnh Phúc" K3.<br />
  Trước đây, với nỗi lo con cái đến tuổi dậy thì sẽ học theo những thói hư và hành vi chưa tốt của nhau, chị Liên đã nhiều lần không kiềm chế được cảm xúc, hay nổi nóng và dạy con bằng đòn roi. Chị tin rằng cách dạy con này sẽ giúp con hiểu nhanh và ngoan hơn. Thế nhưng, sau mỗi lần như vậy, chị lại cảm thấy khoảng cách giữa mình và con ngày một xa dần.<br />
  Mãi cho đến khi biết đến cô Lanh và học viện Minh Trí Thành, lắng nghe những kiến thức cô chia sẻ chị Liên mới dần nhận ra rằng những cách dạy con trước đây hoàn toàn là cách nuôi dạy của những cha mẹ “độc hại”. Hiểu được điều đó, chị bắt đầu hành trình thay đổi bản thân. Từ những hành động nhỏ hằng ngày, chị học cách kiềm chế, lắng nghe, và chia sẻ để trở thành một người mẹ thấu hiểu và dẫn dắt con bằng tình yêu thương thực sự.
        `,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/HOÀNG THỊ KIM LIÊN.mp4",
    thumb: img3,
  },

  {
    title: "Sống mất phương hướng - Lãnh cảm với chồng - Mất kết nối với con",
    description: `
          Sau nhiều năm hôn nhân, chị Huyền dần đánh mất sự kết nối với chồng và con gái – những người chị yêu thương nhất. Từ một gia đình hạnh phúc, giờ chỉ còn là một gia đình với không khi ngột ngạt, đầy những lời trách móc.<br />
  Suốt một thời gian dài, chị và chồng đã tìm mọi cách để cứu vãn, từ các cuộc trò chuyện đến các giải pháp cải thiện hôn nhân, nhưng tất cả đều không mang lại kết quả. Đến khi vợ chồng quyết định chia tay, chị mới nhận ra sự thay đổi đến đau lòng của con gái: Từ một học sinh giỏi, hoạt bát, con trở nên sống khép kín, thu mình và mất kết nối hoàn toàn với mẹ.<br />
  Lúc đó chị chỉ có một khao khát duy nhất là: Tìm lại đứa con từng vui vẻ, năng động như trước. Và chính trong khoảnh khắc ấy, chị biết đến cô Lanh và bắt đầu nhận ra rằng vấn đề hiện tại của mình không phải nằm ở con hay chồng, mà là chị có vấn đề với chính bản thân mình.
        `,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/NGUYỄN THỊ THU HUYỀN.mp4",
    thumb: img5,
  },
  {
    title: "Mẹ áp đặt làm tổn thương con",
    description: `
          Khi bước vào độ tuổi dậy thì, con trai của chị Huyền Trang thường xuyên nóng nảy và khó kiểm soát cảm xúc, cũng như chưa chủ động trong học tập và cuộc sống. Điều này khiến chị lo lắng và băn khoăn không biết làm sao để có thể dẫn dắt con phát triển tốt hơn.<br />
  Trong quá trình tìm kiếm giải pháp, chị đã biết đến cô Lanh và tham gia vào các buổi chia sẻ của cô, nơi chị được lắng nghe những câu chuyện chuyển hóa đầy cảm hứng của các học viên khác. Không chỉ dừng lại ở đó, chị còn tham gia chương trình Kiến tạo offline, nơi chị thực hành các quy trình đầy quyền năng. Nhờ sự kiên nhẫn và đồng hành, mối quan hệ giữa hai mẹ con dần được cải thiện, con đã bình tĩnh hơn và có những thay đổi tích cực.<br />
  Hành trình của chị Huyền Trang không chỉ giúp con phát triển tốt hơn mà còn là nguồn động lực lớn cho những bậc cha mẹ đang tìm kiếm cách thức để dẫn dắt con cái một cách hiệu quả.
        `,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/PHẠM HUYỀN TRANG.mp4",
    thumb: img6,
  },
  {
    title:
      "Ui mẹ mày đúng là người mẹ ao ước!",
    description: `
         "Ui mẹ mày đúng là người mẹ ao ước! - Con kể lại với mình như thế khi được bạn khen về mẹ, lúc đó mình rất vui và tự hào." - Chị Đạt chia sẻ.
Là một người mẹ đơn thân với ba con nhỏ, chị Đạt đã gặp không ít khó khăn trong hành trình nuôi dạy con, nhất là khi phải đảm nhiệm cả vai trò của cha lẫn mẹ. Khi các con ngày càng lớn, chị càng phải đối mặt với nhiều thách thức: các con trở nên lười học, thiếu tự giác, thường bị cuốn vào game và điện thoại. Mặc dù chị khuyên răn, nhắc nhở nhưng lời nói của chị không còn tác động nhiều, khiến chị càng thêm mệt mỏi và bất lực.
Trong quá trình tìm kiếm giải pháp, chị đã biết đến cô Lanh và tham gia các buổi chia sẻ của cô. Tại đây, chị được nghe những câu chuyện chuyển hóa đầy cảm hứng của các học viên khác. Không chỉ dừng lại ở đó, chị còn tham gia chương trình Kiến tạo offline, nơi chị thực hành các quy trình đầy quyền năng. Nhờ sự kiên nhẫn và đồng hành, mối quan hệ giữa mẹ con dần được cải thiện, các con bình tĩnh hơn và có những thay đổi tích cực.`,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/TRỊNH THỊ ĐẠT.mp4",
    thumb: img7,
  },
];
const slideContentHighlight = [
  {
    title:
      "Chương Trình Kiến Tạo Cuộc Đời Mới Offline K3 - Đường Về Hạnh Phúc tháng 10/2024",
    description: `
        ""
      `,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/Chương Trình Kiến Tạo Cuộc Đời Mới Offline K3 - Đường Về Hạnh Phúc tháng 10_2024.mp4",
    thumb: thumbK3,
  },
  {
    title:
      "Chương Trình Kiến Tạo Cuộc Đời Mới Offline K2 - Đường Về Hạnh Phúc tháng 7/2024",
    description: `
        ""
      `,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/Chương Trình Kiến Tạo Cuộc Đời Mới Offline K2 - Đường Về Hạnh Phúc tháng 7_2024.mp4",
    thumb: thumbK2,
  },
  {
    title:
      "Chương Trình Kiến Tạo Cuộc Đời Mới Offline K1 - Đường Về Hạnh Phúc tháng 3/2024",
    description: `
        ""
      `,
    videoSrc:
      "https://cdn.nguyenthilanh.com.vn/resources/landing-page/Chương Trình Kiến Tạo Cuộc Đời Mới Offline K1 - Đường Về Hạnh Phúc tháng 3_2024.mp4",
    thumb: thumbK1,
  },
  

];

const swiperWrapper = document.querySelector(".swiper-wrapper-experience");
document.addEventListener("DOMContentLoaded", () => {
  const initialContent = slideContentExperience[0];
  document.getElementById("slide-title").textContent = initialContent.title;
  document.getElementById("slide-description").innerHTML =
    initialContent.description;
  document.getElementById(
    "slide-bg"
  ).style.backgroundImage = `url(${initialContent.thumb})`;
});
let selectSlideIndex = 0;
let selectSlideIndex2 = 0;
const swiper = new Swiper(".swiper1", {
  spaceBetween: 20,
  loop: true,

  on: {
    slideChange: (swiper) => console.log(swiper, "1688"),
    slideNextTransitionEnd: function () {
      selectSlideIndex++;
      const activeContent =
        slideContentExperience[
          selectSlideIndex % slideContentExperience.length
        ];

      document.getElementById(
        "slide-bg"
      ).style.backgroundImage = `url(${activeContent.thumb})`;
      document.getElementById("slide-title").textContent = activeContent.title;

      document.getElementById("slide-description").innerHTML =
        activeContent.description;
      document.getElementById("videoPlayer2").src = activeContent.videoSrc;
    },
    slidePrevTransitionEnd: function () {
      selectSlideIndex--;
      const activeContent =
        slideContentExperience[
          selectSlideIndex % slideContentExperience.length
        ];

      document.getElementById(
        "slide-bg"
      ).style.backgroundImage = `url(${activeContent.thumb})`;
      document.getElementById("slide-title").textContent = activeContent.title;

      document.getElementById("slide-description").innerHTML =
        activeContent.description;
      document.getElementById("videoPlayer2").src = activeContent.videoSrc;
    },
  },
  navigation: {
    nextEl: ".my-swiper-button-next",
    prevEl: ".my-swiper-button-prev",
  },
  breakpoints: {
    1024: {
      slidesPerView: 4,
    },
    0: {
      slidesPerView: 1,
    },
  },
  speed: 600, // Transition speed
});
const openVideoBtn2 = document.getElementById("openVideoBtn2");
function openVideoExperience() {
  videoPlayer.play().catch((error) => {
    console.error("Video auto-play failed:", error);
  });

  modal2.classList.remove("hidden");
}
openVideoBtn2.addEventListener("click", openVideoExperience);
slideContentExperience.forEach((slide, index) => {
  const slideDiv = document.createElement("div");
  slideDiv.className = "swiper-slide";

  slideDiv.innerHTML = `
  <div class="flex flex-col items-center overflow-hidden w-full">
    <div data-target-index="${index}" class="w-full h-[174px] rounded-lg cursor-pointer img-experience bg-cover bg-no-repeat" style="background-image: url('${slide.thumb}');">

    </div>
    <div class="px-4 py-2 text-center text-sm lg:text-base font-semibold text-[#fafafa]">
      ${slide.title}
    </div>
  </div>
  `;
  slideDiv.addEventListener("click", (event) => {
    const targetDiv = event.target.closest(".img-experience");
    if (targetDiv) {
      const dataIndex = targetDiv.getAttribute("data-target-index");
      const activeIndex = dataIndex;
      const activeContent =
        slideContentExperience[activeIndex % slideContentExperience.length];

      document.getElementById(
        "slide-bg"
      ).style.backgroundImage = `url(${activeContent.thumb})`;
      document.getElementById("slide-title").textContent = activeContent.title;

      document.getElementById("slide-description").innerHTML =
        activeContent.description;

      document.getElementById("videoPlayer2").src = activeContent.videoSrc;
      openVideoExperience();
    }
  });
  swiperWrapper.appendChild(slideDiv);
});

const swiperHighLight = new Swiper(".swiper2", {

  spaceBetween: 20,
  loop:true,
  on: {
    slideNextTransitionEnd: function () {
      selectSlideIndex2++;
      const activeContent =
        slideContentHighlight[selectSlideIndex2 % slideContentHighlight.length];

      document.getElementById(
        "slide-bg-highlight"
      ).style.backgroundImage = `url(${activeContent.thumb})`;
      document.getElementById("slide-title-highlight").textContent =
        activeContent.title;

      document.getElementById("videoPlayer2").src = activeContent.videoSrc;
    },
    slidePrevTransitionEnd: function () {
      selectSlideIndex2--;
      const activeContent =
        slideContentHighlight[selectSlideIndex2 % slideContentHighlight.length];

      document.getElementById(
        "slide-bg-highlight"
      ).style.backgroundImage = `url(${activeContent.thumb})`;
      document.getElementById("slide-title-hightlight").textContent =
        activeContent.title;

      document.getElementById("videoPlayer2").src = activeContent.videoSrc;
    },
  },
  navigation: {
    nextEl: ".my-swiper-button-next-highlight",
    prevEl: ".my-swiper-button-prev-highlight",
  },
  breakpoints: {
    1024: {
      slidesPerView: 3,
    },

    0: {
      slidesPerView: 1,
    },
  },
  autoplay: false,
  speed: 600,
});
const modal2 = document.getElementById("videoModal2");

const openVideoBtnHighlight = document.getElementById("openVideoBtnHighlight");
const videoPlayer = document.getElementById("videoPlayer2");

const closeVideoBtn2 = document.getElementById("closeVideoBtn2");
function openVideoHighlight() {
  videoPlayer.play().catch((error) => {
    console.error("Video auto-play failed:", error);
  });

  modal2.classList.remove("hidden");
}
openVideoBtnHighlight.addEventListener("click", openVideoHighlight);

closeVideoBtn2.addEventListener("click", () => {
  // Pause the video and clear the source when closing
  videoPlayer.pause();

  // Hide the modal
  modal2.classList.add("hidden");
});

const swiperWrapperHighlight = document.querySelector(
  ".swiper-wrapper-highlight"
);

slideContentHighlight.forEach((slide, index) => {
  const slideDiv = document.createElement("div");
  slideDiv.className = "swiper-slide";

  slideDiv.innerHTML = `
  <div class="flex flex-col items-center overflow-hidden w-full">
    <div data-target-index=${index} class="w-full h-[250px] rounded-lg cursor-pointer bg-cover img-highlight bg-no-repeat" style="background-image: url('${slide.thumb}');">

    </div>
    <div class="px-4 py-2 text-center text-sm lg:text-base font-semibold text-[#fafafa]">
      ${slide.title}
    </div>
  </div>
`;

  // slideDiv.addEventListener("click", () => {
  //   swiperHighLight.slideTo(index);
  // });
  slideDiv.addEventListener("click", (event) => {
    const targetDiv = event.target.closest(".img-highlight");
    if (targetDiv) {
      const dataIndex = targetDiv.getAttribute("data-target-index");
      const activeIndex = dataIndex;
      const activeContent = slideContentHighlight[activeIndex];
      document.getElementById(
        "slide-bg-highlight"
      ).style.backgroundImage = `url(${activeContent.thumb})`;
      document.getElementById("slide-title-highlight").textContent =
        activeContent.title;

      document.getElementById("videoPlayer2").src = activeContent.videoSrc;
      openVideoHighlight();
    }
  });
  swiperWrapperHighlight.appendChild(slideDiv);
});

function changeExperienceTab(tab) {
  const experienceContentElement =
    document.getElementById("experience-content");
  const highlightContentElement = document.getElementById("highlight-content");

  const experienceTab = document.getElementById("experience-tab");
  const highlightTab = document.getElementById("highlight-tab");

  if (tab === "experience") {
    experienceContentElement.classList.remove("hidden");
    highlightContentElement.classList.add("hidden");

    experienceTab.classList.add("active-tab");
    experienceTab.classList.remove("inactive-tab");
    highlightTab.classList.add("inactive-tab");
    highlightTab.classList.remove("active-tab");
  } else {
    const initialContent = slideContentHighlight[0];
    document.getElementById("slide-title-highlight").textContent =
      initialContent.title;

    document.getElementById(
      "slide-bg-highlight"
    ).style.backgroundImage = `url(${initialContent.thumb})`;
    document.getElementById("videoPlayer2").src = initialContent.videoSrc;

    experienceContentElement.classList.add("hidden");
    highlightContentElement.classList.remove("hidden");

    highlightTab.classList.add("active-tab");
    highlightTab.classList.remove("inactive-tab");
    experienceTab.classList.add("inactive-tab");
    experienceTab.classList.remove("active-tab");
  }
}

window.changeExperienceTab = changeExperienceTab;
